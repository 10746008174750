import React from "react";
import { List, Datagrid, TextField, FunctionField } from "react-admin";
import { IconButton } from '@material-ui/core/';
import { Pagination } from "../../../Components";
import { Delete } from '@material-ui/icons';
import { CryptoWalletsProvider } from '../../../Providers'
import useAlerts from '../../../hooks/useAlerts';
import useReset from '../../../hooks/useReset';
import { useAccessRole } from "../../../hooks";

const CustodyErrorCryptoWalletsList = (props) => {
  const { successMessage, errorMessage } = useAlerts();
  const { resetList } = useReset();
  const { isAccess } = useAccessRole();

  const handleMarkAsChecked = async(record_id, data) => {
    try {
      await CryptoWalletsProvider.updateCryptoWallet(record_id, data);
    } catch (error) {
      errorMessage(error?.data?.message || "Action couldn't be performed");
    }
    resetList()
  }

  return (
    <List
      {...props}
      title={"Billeteras con transferencia a custodia fallida"}
      pagination={<Pagination />}
      exporter={false}
      bulkActionButtons={false}
      debounce={1000}
    >
      <Datagrid>
        <TextField source="id" label="Id" />
        <TextField source="attributes.network_name" label="Red" />
        <TextField source="attributes.address" label="Dirección" />
        <FunctionField
          label="Código externo"
          render={(record) => (
            <a
              href={record.attributes.external_provider_url}
              target='_blank'
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline', color: 'inherit' }}
            >
              {record.attributes.external_provider_id}
            </a>
          )}
        />
        <FunctionField
          label="Usuario"
          render={(record) => (
            <a
              href={record.attributes.user_detail_url}
              target='_blank'
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline', color: 'inherit' }}
            >
              {record.attributes.user_email}
            </a>
          )}
        />
        {
          isAccess('edit_crypto_addresses') && 
          <FunctionField
            label="Marcar como revisada"
            render={(record) => (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IconButton
                  onClick={
                    async () => handleMarkAsChecked(
                      record.id,
                      {
                        has_custody_transfer_flow_failed: !record.attributes.has_custody_transfer_flow_failed
                      }
                    )
                  }
                  color="primary"
                >
                  <Delete />
                </IconButton>
              </div>
            )}
          />
        }
      </Datagrid>
    </List>
  );
};

export default CustodyErrorCryptoWalletsList;
