import React from "react";
import { Link } from "react-router-dom";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  EditButton,
  FunctionField,
} from "react-admin";
import useAccessRole from "../../../hooks/useAccessRole";
import { DOCUMENT_TYPE, REFERRER_PRIZE_TYPE, PAYMENT_PROCESSORS } from "../../../Utils";
import { Flag, MoneyTransactionField } from "../../../Components";
import CountryFilter from "./components/Filters";

const CountryList = (props) => {
  const { isAccess } = useAccessRole();

  const ButtonServices = (prop) => {
    const {
      record: { attributes },
    } = prop;
    if (attributes.is_service_payment === true && isAccess('show_services')) {
      return (
        <Link to={`/services`}>
          <button
            style={{
              backgroundColor: "#00F5A5",
              color: "white",
              border: "none",
              borderRadius: "4px",
              borderColor: "#fff",
              padding: "7px",
              cursor: "pointer",
            }}
          >
            Configurar servicios
          </button>
        </Link>
      );
    }

    return <></>;
  };

  return (
    <List
      {...props}
      title={'Países'}
      bulkActionButtons={false}
      exporter={false}
      filters={<CountryFilter />}
      sort={{ field: 'id', order: 'ASC' }}
      debounce={1000}
    >
      <Datagrid
        classes={{
          table: 'col_stikcy_3'
        }}
      >
        <TextField
          source={'id'}
          label={'Id'}
        />
        <Flag {...props} />
        <FunctionField
          label={'Nombre'}
          render={record => record.attributes.name}
        />
        <TextField
          source={'attributes.currency'}
          label={'Moneda'}
        />
        <TextField
          source={'attributes.iso_code'}
          label={'Código ISO'}
        />
        <TextField
          source={'attributes.currency_name'}
          label={'Moneda local'}
        />
        <TextField
          source={'attributes.currency_iso_code'}
          label={'Código ISO de Moneda local'}
        />
        <TextField
          source={'attributes.currency_plural_name'}
          label={'Moneda local en plural'}
        />
        <TextField
          source={'attributes.size_of_digits_for_sms'}
          label={'Cantidad de digitos SMS'}
        />
        <BooleanField
          source={'attributes.is_referrals_system'}
          label={'Sistema de referidos'}
        />
        <FunctionField
          label={'¿Quién recibe dinero en referidos?'}
          render={record => (REFERRER_PRIZE_TYPE.filter(item => (
            item.id === record.attributes.send_prize_to
          ))[0].name)}
        />
        <FunctionField
          label={'Procesador de pago'}
          render={record => (PAYMENT_PROCESSORS.filter(item => (
            item.id === record.attributes.payment_processor
          ))[0].name)}
        />
        <MoneyTransactionField
          attribute={'min_deposit_amount_referrer'}
          label={'Monto mínimo para recibir recarga por referido'}
          isLabel={false}
        />
        <MoneyTransactionField
          attribute={'referrer_prize'}
          label={'Monto a recibir por referido'}
          isLabel={false}
        />
        <MoneyTransactionField
          attribute={'min_amount_exchange'}
          label={'Monto mínimo para intercambiar'}
          isLabel={false}
        />
        <MoneyTransactionField
          attribute={'transfer_min_amount_usd'}
          label={'Monto mínimo para transferir en USD'}
          isLabel={false}
          isCurrency={true}
          fieldCurrency={'USD'}
        />
        <MoneyTransactionField
          attribute={'min_admin_balance_exchange'}
          label={'Monto mínimo de balance del admin'}
          isLabel={false}
        />
        <MoneyTransactionField
          attribute={'min_amount_payment'}
          label={'Monto mínimo para recibir pagos'}
          isLabel={false}
        />
        <MoneyTransactionField
          attribute={'min_amount_sents'}
          label={'Monto mínimo para envios internos en USD'}
          isLabel={false}
          fieldCurrency={'USD'}
        />
        <MoneyTransactionField
          attribute={'min_amount_recharge'}
          label={'Monto mínimo para recargas'}
          isLabel={false}
        />
        <MoneyTransactionField
          attribute={'min_amount_withdrawal'}
          label={'Monto mínimo retiros cuenta propia'}
          isLabel={false}
        />
        <MoneyTransactionField
          attribute={'withdrawal_fee'}
          label={'Comisión de retiro %'}
          isLabel={false}
          isCurrency={false}
        />
        <MoneyTransactionField
          attribute={'deposit_fee'}
          label={'Comisión de recarga %'}
          isLabel={false}
          isCurrency={false}
        />
        <MoneyTransactionField
          attribute={'card_cost_individual'}
          label={'Costo de tarjeta (natural) en USD'}
          isLabel={false}
          fieldCurrency={'USD'}
        />
        <MoneyTransactionField
          attribute={'card_cost_business'}
          label={'Costo de tarjeta (business) en USD'}
          isLabel={false}
          fieldCurrency={'USD'}
        />
        <MoneyTransactionField
          attribute={'usd_account_cost_individual'}
          label={'Costo de cuenta (natural) en EE.UU.'}
          isLabel={false}
          fieldCurrency={'USD'}
        />
        <MoneyTransactionField
          attribute={'usd_account_cost_business'}
          label={'Costo de cuenta (business) en EE.UU.'}
          isLabel={false}
          fieldCurrency={'USD'}
        />
        <MoneyTransactionField
          attribute={'card_reapplication_cost_individual'}
          label={'Resolicitud de tarjeta (natural) en USD'}
          isLabel={false}
          fieldCurrency={'USD'}
        />
        <MoneyTransactionField
          attribute={'card_reapplication_cost_business'}
          label={'Resolicitud de tarjeta (business) en USD'}
          isLabel={false}
          fieldCurrency={'USD'}
        />
        <FunctionField
          label={'Tipos de documentos para recargar'}
          render={record => {
            if (record.attributes.recharge_documents_types.length === 0) {
              return 'Todos';
            } else {
              let options = '';
              record.attributes.recharge_documents_types.map(doc => {
                let name = '';
                DOCUMENT_TYPE.map(item => {
                  if (item.id === doc) {
                    name = item.name
                  }
                });

                options += ` ${name}\n
                                
                                `;
              });

              return options;
            }
          }}
        />
        <TextField
          source={'attributes.currency_decimals'}
          label={'Cantidad decimales'}
        />
        <TextField
          source={'attributes.flag_url'}
          label={'URL de la bandera'}
        />
        <BooleanField
          source={'attributes.is_cashout'}
          label={'Retiros'}
        />
        <BooleanField
          source={'attributes.is_sent_receive'}
          label={'Recibir envíos'}
        />
        <BooleanField
          source={'attributes.is_sent_available'}
          label={'Enviar'}
        />
        <BooleanField
          source={'attributes.is_register'}
          label={'País de residencia'}
        />
        <BooleanField
          source={'attributes.is_birth'}
          label={'País de nacimiento'}
        />
        <BooleanField
          source={'attributes.is_btc'}
          label={'Bitcoin'}
        />
        <BooleanField
          source={'attributes.is_recharge'}
          label={'Recargas'}
        />
        <BooleanField
          source={'attributes.is_payments'}
          label={'Pagos'}
        />
        <BooleanField
          source={'attributes.is_business_payments'}
          label={'Pagos business'}
        />
        <BooleanField
          source={'attributes.is_transfer'}
          label={'Remesas'}
        />
        <BooleanField
          source={'attributes.is_sent_vita_card'}
          label={'Enviar a Vita Cards'}
        />
        <BooleanField
          source={'attributes.is_vita_card'}
          label={'Recibir Vita Cards'}
        />
        <BooleanField
          source={"attributes.is_service_payment"}
          label={"Servicios"}
        />
        <BooleanField
          source={'attributes.is_business_api'}
          label={'Api Business'}
        />
        <BooleanField
          source={'attributes.is_add_international_favorites'}
          label={'Enviar a terceros en país de residencia'}
        />
        <BooleanField
          source={'attributes.allow_automatic_payments'}
          label={'Permite pagos automáticos fiat'}
        />
        <BooleanField
          source={'attributes.allow_automatic_payments_crypto'}
          label={'Permite pagos automáticos crypto'}
        />
        <ButtonServices />
        <TextField
          source={'attributes.society'}
          label={'Texto de la sociedad'}
        />
        <TextField
          source={'attributes.message_time_withdrawal'}
          label={'Tiempo estimado'}
        />
        <BooleanField
          source={'attributes.is_wire_configuration'}
          label={'Envíos WIRE'}
        />
        <BooleanField
          source={'attributes.is_usd_account'}
          label={'Cuentas USD'}
        />
        <BooleanField
          source={'attributes.is_beneficiary_search'}
          label={'Permite autollenar información en transferencias'}
        />
        <TextField
          source={'attributes.benericiary_search_label'}
          label={'Texto para mostrar del autollenado'}
        />
        <TextField
          source={'attributes.account_code_regex'}
          label={'Expresión regular para el autollenado'}
        />
        <TextField
          source={'attributes.beneficiary_search_provider_label'}
          label={'Proveedor para el autollenado'}
        />
        {isAccess('edit_countries') && <EditButton />}
      </Datagrid>
    </List>
  );
}

export default CountryList;
