import React, { useEffect, useState }                             from 'react';
import { Container, Row, Col }                                    from 'react-grid-system';
import { withStyles }                                             from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { BooleanInput, Edit, SimpleForm, TextInput, SelectInput, NumberInput } from 'react-admin';

import TbkLogo                                    from '../../../Assets/images/tbk.svg';
import OnfidoLogo                                 from '../../../Assets/images/onfido.png';
import AddBalanceForAdmin                         from '../../../Components/AddBalanceForAdmin';
import { Flag }                                   from '../../../Components';
import { CountriesProvider }                      from '../../../Providers';
import { currencyCountries, currenciesCountries } from '../../../Config/constants';
import { COINS, toMoney, TO_NUMBER }              from '../../../Helpers/MoneyHelper';
import BinanceProvider from "../../../Providers/Binance";
import UsdcWithdrawalsProvider from "../../../Providers/UsdcWithdrawals";
import useAlerts from '../../../hooks/useAlerts';

import { styles, stylesHoc } from './styles';
import { PostEditToolbar }   from './components';

const ConfigurationEdit = (props) => {
    const { history } = props;
    const [countries, setCountries] = useState([]);
    const [isCheckedDeleteDailyLimitAMountClp, setCheckedDeleteDailyLimitAMountClp] = useState(false);
    const [isCheckedDeleteDailyLimitAMountUsd, setCheckedDeleteDailyLimitAMountUsd] = useState(false);
    const [isCheckedDeleteDailyLimitRechargeTransbank, setCheckedDeleteDailyLimitRechargeTransbank] = useState(false);
    const [isCheckedDeleteMountlyLimitRechargeTransbank, setCheckedDeleteMountlyLimitRechargeTransbank] = useState(false);
    const [isUpdateLimits, setUpdateLimits] = useState(false);
    const [isUpdateTransbankRecharge, setUpdateTransbankRecharge] = useState(false);
    const [isUpdateBiometricVerification, setUpdateBiometricVerification] = useState(false);
    const {errorMessage, successMessage} = useAlerts();

    useEffect( () => {
        let isFetchCountries = true;

        (async () => {
            try {
                if (isFetchCountries || countries.length === 0) {
                    const response = await CountriesProvider.fetchCountriesAdmin(true);
                    setCountries(response.data.countries.data);
                }
            } catch (e) { }
        })();

        return () => { isFetchCountries = false; setCountries([]); };
    }, []);

    const balanceUsdt = async () => {
        try {
            let message = await BinanceProvider.binanceBalance();
            successMessage(message.data.message);
        } catch (e) {
            errorMessage(e.data.message);
        }
    }

    const balanceUsdc = async () => {
        try {
            let message = await UsdcWithdrawalsProvider.circleBalance();
            successMessage(message.data.message);
        } catch (e) {
            errorMessage(e.data.message);
        }
    }

    const renderFees = () => {
        const FeeCountries = [];
        countries.map((country) => {
            const { attributes: { is_cashout, name } } = country;
            if (name !== undefined && name !== null && name !== '' && currencyCountries[name]) {
                if (is_cashout || true) {
                    FeeCountries.push(
                        <Container key={`key_${currencyCountries[name].toLowerCase()}`} item ms={12}>
                            <Row container direction="row" justify="center" alignItems="center" style={styles.divisorTop}>
                                <Col item style={styles.divisorCoin}>
                                    <div style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                        <Flag record={{attributes: {name}}} />
                                    </div>
                                    <h4 style={styles.titleCoin}>
                                        {currenciesCountries[name]}
                                    </h4>
                                    <h3 style={styles.titleCoin}>
                                        Dólar estadounidense
                                    </h3>
                                    <Col container direction="column" justify="center" alignItems="center">
                                        <Col item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                            <TextInput
                                                label={'VENTA'}
                                                source={`usd_sell_fee_${currencyCountries[name].toLowerCase()}`}
                                                format={v => toMoney(v, COINS.CLP.COIN)}
                                                parse={v => TO_NUMBER(v, COINS.CLP.COIN)}
                                            />
                                        </Col>
                                        <Col item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                            <TextInput
                                                label={'COMPRA'}
                                                source={`usd_buy_fee_${currencyCountries[name].toLowerCase()}`}
                                                format={v => toMoney(v, COINS.CLP.COIN)}
                                                parse={v => TO_NUMBER(v, COINS.CLP.COIN)}
                                            />
                                        </Col>
                                    </Col>
                                </Col>
                            </Row>
                        </Container>
                    );
                }
            }

            return null;
        });

        return FeeCountries;
    };

    const renderUpdateLimits = () => {
        if (isUpdateLimits)
            return (
                <>
                    <Col item sm={6} style={styles.divisorCoin}>
                        <div style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                            <Flag record={{attributes: {name: 'Chile'}}} />
                        </div>
                        <h4 style={styles.titleCoin}>
                            Peso chileno CLP
                        </h4>
                        {!isCheckedDeleteDailyLimitAMountClp ? (
                            <Col sm={12} style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                <TextInput
                                    label={'LÍMITE'}
                                    source={'daily_limit_amount_clp'}
                                    format={v => !(v === null) ? toMoney(v, COINS.CLP.COIN) : 'sin límite'}
                                    parse={v => !(v === null) ? TO_NUMBER(v, COINS.CLP.COIN) : 'sin límite'}
                                />
                            </Col>) : null
                        }
                        <Col sm={12} item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                            <BooleanInput
                                label="Eliminar limite CLP"
                                source="delete_daily_limit_amount_clp"
                                onChange={(event) => {
                                    const { target : { checked }} = event;
                                    setCheckedDeleteDailyLimitAMountClp(checked);
                                }}
                            />
                        </Col>
                    </Col>
                    <Col sm={6} item style={styles.divisorCoin}>
                        <div style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                            <Flag record={{attributes: {name: 'Estados Unidos'}}} />
                        </div>
                        <h4 style={styles.titleCoin}>
                            Dólar estadounidense USD
                        </h4>
                        {!isCheckedDeleteDailyLimitAMountUsd ? (
                            <Col sm={12} item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                <TextInput
                                    label={'LÍMITE'}
                                    source={'daily_limit_amount_usd'}
                                    format={v => !(v === null) ? toMoney(v, COINS.CLP.COIN) : 'sin límite'}
                                    parse={v => !(v === null) ? TO_NUMBER(v, COINS.CLP.COIN) : 'sin límite'}
                                />
                            </Col>) : null
                        }
                        <Col sm={12} item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                            <BooleanInput
                                label="Eliminar limite USD"
                                source="delete_daily_limit_amount_usd"
                                onChange={(event) => {
                                    const { target : { checked }} = event;
                                    setCheckedDeleteDailyLimitAMountUsd(checked);
                                }}
                            />
                        </Col>
                    </Col>
                </>
            );
    };

    const renderUpdateTransbankRechargeLimits = () => {
        if (isUpdateTransbankRecharge)
            return (
                <>
                    <Col item sm={6} style={styles.divisorCoin}>
                        <h4 style={styles.titleCoin}>
                            Límite diario
                        </h4>
                        {!isCheckedDeleteDailyLimitRechargeTransbank ? (
                            <Col sm={12} style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                <TextInput
                                    label={'LÍMITE'}
                                    source={'daily_transbank_recharge_limit'}
                                    format={v => !(v === null) ? toMoney(v, COINS.CLP.COIN) : 'sin límite'}
                                    parse={v => !(v === null) ? TO_NUMBER(v, COINS.CLP.COIN) : 'sin límite'}
                                />
                            </Col>) : null
                        }
                        <Col sm={12} item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                            <BooleanInput
                                label="Eliminar limite"
                                source="delete_daily_transbank_recharge_limit"
                                onChange={(event) => {
                                    const { target : { checked }} = event;
                                    setCheckedDeleteDailyLimitRechargeTransbank(checked);
                                }}
                            />
                        </Col>
                    </Col>
                    <Col sm={6} item style={styles.divisorCoin}>
                        <h4 style={styles.titleCoin}>
                            Límite mensual
                        </h4>
                        {!isCheckedDeleteMountlyLimitRechargeTransbank ? (
                            <Col sm={12} item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                <TextInput
                                    label={'LÍMITE'}
                                    source={'monthly_transbank_recharge_limit'}
                                    format={v => !(v === null) ? toMoney(v, COINS.CLP.COIN) : 'sin límite'}
                                    parse={v => !(v === null) ? TO_NUMBER(v, COINS.CLP.COIN) : 'sin límite'}
                                />
                            </Col>) : null
                        }
                        <Col sm={12} item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                            <BooleanInput
                                label="Eliminar limite"
                                source="delete_monthly_transbank_recharge_limit"
                                onChange={(event) => {
                                    const { target : { checked }} = event;
                                    setCheckedDeleteMountlyLimitRechargeTransbank(checked);
                                }}
                            />
                        </Col>
                    </Col>
                </>
            );
    };

    return (
        <Edit {...props} title={`Editar configuración`}>
            <SimpleForm toolbar={<PostEditToolbar />}>
                <Container style={{width: '100%'}}>
                <Row>
                    <Col sm={12}>
                        <Row>
                            <Col item sm={12} style={{...styles.description, alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                                <img style={{width: 300}} src={OnfidoLogo} />
                            </Col>
                            <Col sm={12} style={{alignItems: 'center', justifyContent: 'center'}}>
                                <div style={{width: '100%', display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                    <BooleanInput
                                        style={{width: 300, display: 'flex', textAlign: 'center', alignContent: 'center'}}
                                        label="Verificación biométrica"
                                        source="biometric_verification"
                                        onChange={(event) => {
                                            const { target : { checked }} = event;
                                            setUpdateBiometricVerification(checked);
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col sm={12} style={{alignItems: 'center', justifyContent: 'center'}}>
                                <div style={{width: '100%', display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                    <BooleanInput
                                        style={{width: 300, display: 'flex', textAlign: 'center', alignContent: 'center'}}
                                        label="Código de verificación de cuenta"
                                        source="is_email_code_verification"
                                    />
                                    <NumberInput
                                    style={{width: 200, display: 'flex', textAlign: 'center', alignContent: 'center'}}
                                        label={'Segundos para expiración de código'}
                                        source={'verification_code_expiration_time'}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} style={{backgroundColor: 'rgba(0,0,0,.2)'}}>
                        <Row>
                            <Col item sm={12} style={{...styles.description, alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                                <img style={{width: 300}} src={TbkLogo} />
                            </Col>
                            <Col sm={12} style={{alignItems: 'center', justifyContent: 'center'}}>
                                <div style={{width: '100%', display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                    <BooleanInput
                                        style={{width: 300, display: 'flex', textAlign: 'center', alignContent: 'center'}}
                                        label="Modificar límites"
                                        source="update_transbank_recharge_limits"
                                        onChange={(event) => {
                                            const { target : { checked }} = event;
                                            setUpdateTransbankRecharge(checked);
                                        }}
                                    />
                                </div>
                            </Col>
                            {renderUpdateTransbankRechargeLimits()}
                        </Row>
                    </Col>
                    <Col sm={6} style={{paddingTop: 50}}>
                        <Row>
                            <Col item sm={12} style={styles.description}>
                                Límites diarios para todos los usuarios
                            </Col>
                            <Col sm={12} style={{alignItems: 'center', justifyContent: 'center'}}>
                                <div style={{width: '100%', display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                    <BooleanInput
                                        style={{width: 300, display: 'flex', textAlign: 'center', alignContent: 'center'}}
                                        label="Modificar límites diarios"
                                        source="update_limits"
                                        onChange={(event) => {
                                            const { target : { checked }} = event;
                                            setUpdateLimits(checked);
                                        }}
                                    />
                                </div>
                            </Col>
                            {renderUpdateLimits()}
                        </Row>
                    </Col>
                    <Col sm={4} style={styles.divisorCoin}>
                        <h3 style={styles.titleCoin}>
                            Frecuencia de actualización de precios (minutos)
                        </h3>
                        <Row container direction="column" justify="center" alignItems="center">
                            <Col item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                <NumberInput
                                    label={'Precios FIAT'}
                                    source={'minutes_update_fiat_prices'}
                                />
                            </Col>
                            <Col item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                <NumberInput
                                    label={'Precios BTC'}
                                    source={'minutes_update_btc_prices'}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={4} style={styles.divisorCoin}>
                        <h3 style={styles.titleCoin}>
                            Spread de proveedores de precios
                        </h3>
                        <Row container direction="column" justify="center" alignItems="center">
                            <Col item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                <NumberInput
                                    label={'Spread de vector capital (CLP)'}
                                    source={'vector_capital_spread'}
                                />
                            </Col>
                            <Col item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                <NumberInput
                                    label={'Spread de setfx (COP)'}
                                    source={'setfx_spread'}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={4} style={styles.divisorCoin}>
                        <h3 style={styles.titleCoin}>
                            Precisión de precios fiat y crypto
                        </h3>
                        <Row container direction="column" justify="center" alignItems="center">
                            <Col item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                <NumberInput
                                    label={'Precisión FIAT'}
                                    source={'spot_precision'}
                                />
                            </Col>
                            <Col item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                <NumberInput
                                    label={'Precisión CRYPTO'}
                                    source={'crypto_precision'}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <AddBalanceForAdmin history={history} />
                    <Col sm={4} style={styles.divisorCoin}>
                        <h3 style={styles.titleCoin}>
                            Balancear saldo USDT del administrador
                        </h3>
                        <Row container direction="column" justify="center" alignItems="center">
                            <Col item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                <Button
                                    onClick={balanceUsdt}
                                    style={{backgroundColor: '#00adfd'}}
                                >
                                    Balancear
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={4} style={styles.divisorCoin}>
                        <h3 style={styles.titleCoin}>
                            Balancear saldo USDC del administrador
                        </h3>
                        <Row container direction="column" justify="center" alignItems="center">
                            <Col item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                <Button
                                    onClick={balanceUsdc}
                                    style={{backgroundColor: '#00adfd'}}
                                >
                                    Balancear
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    {/*<Col item sm={12} style={{...styles.description, paddingTop: 50}}>
                        Porcentajes de compra y venta
                    </Col>
                    <Col item sm={12}>
                        <Row style={styles.divisorTop}>
                            <Col item xs={12}>
                                <div style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                    <Flag record={{attributes: {name: 'Chile'}}} />
                                </div>
                                <h4 style={styles.titleCoin}>
                                    Peso chileno CLP
                                </h4>
                            </Col>
                            <Col sm={4} item style={styles.divisorCoin}>
                                <h3 style={styles.titleCoin}>
                                    Dólar estadounidense
                                </h3>
                                <Row container direction="column" justify="center" alignItems="center">
                                    <Col sm={12} style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                        <TextInput
                                            label={'VENTA'}
                                            source={'usd_sell_fee_clp'}
                                            format={v => toMoney(v, COINS.CLP.COIN)}
                                            parse={v => TO_NUMBER(v, COINS.CLP.COIN)}
                                        />
                                    </Col>
                                    <Col sm={12} style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                        <TextInput
                                            label={'COMPRA'}
                                            source={'usd_buy_fee_clp'}
                                            format={v => toMoney(v, COINS.CLP.COIN)}
                                            parse={v => TO_NUMBER(v, COINS.CLP.COIN)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={4} style={styles.divisorCoin}>
                                <h3 style={styles.titleCoin}>
                                    Bitcoin
                                </h3>
                                <Row container direction="column" justify="center" alignItems="center">
                                    <Col item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                        <TextInput
                                            label={'VENTA'}
                                            source={'btc_sell_fee'}
                                            format={v => toMoney(v, COINS.CLP.COIN)}
                                            parse={v => TO_NUMBER(v, COINS.CLP.COIN)}
                                        />
                                    </Col>
                                    <Col item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                        <TextInput
                                            label={'COMPRA'}
                                            source={'btc_buy_fee'}
                                            format={v => toMoney(v, COINS.CLP.COIN)}
                                            parse={v => TO_NUMBER(v, COINS.CLP.COIN)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={4} style={styles.divisorCoin}>
                                <h3 style={styles.titleCoin}>
                                    Ethereum
                                </h3>
                                <Row container direction="column" justify="center" alignItems="center">
                                    <Col item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                        <TextInput
                                            label={'VENTA'}
                                            source={'eth_sell_fee'}
                                            format={v => toMoney(v, COINS.CLP.COIN)}
                                            parse={v => TO_NUMBER(v, COINS.CLP.COIN)}
                                        />
                                    </Col>
                                    <Col item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                        <TextInput
                                            label={'COMPRA'}
                                            source={'eth_buy_fee'}
                                            format={v => toMoney(v, COINS.CLP.COIN)}
                                            parse={v => TO_NUMBER(v, COINS.CLP.COIN)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col item ms={12}>
                        <Row container direction="row" justify="center" alignItems="center" style={styles.divisorTop}>
                            <Col item xs={12}>
                                <div style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                    <Flag record={{attributes: {name: 'Estados Unidos'}}} />
                                </div>
                                <h4 style={styles.titleCoin}>
                                    Dólar estadounidense USD
                                </h4>
                            </Col>
                            <Col item style={styles.divisorCoin}>
                                <h3 style={styles.titleCoin}>
                                    Bitcoin
                                </h3>
                                <Row container direction="column" justify="center" alignItems="center">
                                    <Col item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                        <TextInput
                                            label={'VENTA'}
                                            source={'btc_sell_fee_usd'}
                                            format={v => toMoney(v, COINS.CLP.COIN)}
                                            parse={v => TO_NUMBER(v, COINS.CLP.COIN)}
                                        />
                                    </Col>
                                    <Col item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                        <TextInput
                                            label={'COMPRA'}
                                            source={'btc_buy_fee_usd'}
                                            format={v => toMoney(v, COINS.CLP.COIN)}
                                            parse={v => TO_NUMBER(v, COINS.CLP.COIN)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col item style={styles.divisorCoin}>
                                <h3 style={styles.titleCoin}>
                                    Ethereum
                                </h3>
                                <Row container direction="column" justify="center" alignItems="center">
                                    <Col item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                        <TextInput
                                            label={'VENTA'}
                                            source={'eth_sell_fee_usd'}
                                            format={v => toMoney(v, COINS.CLP.COIN)}
                                            parse={v => TO_NUMBER(v, COINS.CLP.COIN)}
                                        />
                                    </Col>
                                    <Col item style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                                        <TextInput
                                            label={'COMPRA'}
                                            source={'eth_buy_fee_usd'}
                                            format={v => toMoney(v, COINS.CLP.COIN)}
                                            parse={v => TO_NUMBER(v, COINS.CLP.COIN)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>*/}
                </Row>
                </Container>
                {/*<Container style={{width: '100%', paddingTop: 70}}>
                    <Row>
                        <Col item sm={12} style={{...styles.description, paddingTop: 50}}>
                            Porcentajes de compra y venta de países
                        </Col>
                        {renderFees()}
                    </Row>
                </Container>*/}
            </SimpleForm>
        </Edit>
    );
};

export default withStyles(stylesHoc)(ConfigurationEdit);
