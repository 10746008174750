import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import Navigation from '../../../../../../../Navigation';
import useAccessRole from '../../../../../../../hooks/useAccessRole';

function EditButton({ id = 0 }) {
  const { history } = Navigation.nav();
  const { isAccess } = useAccessRole();

  const goToEditUser = () => history.push(`/usuarios/${id}`);

  if (!isAccess('edit_users')) {
    return null;
  }

  return (
    <Button variant="outlined" onClick={goToEditUser}>
      Editar
    </Button>
  );
}

EditButton.propTypes = {
  id: PropTypes.number.isRequired,
};

export default EditButton;
