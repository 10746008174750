import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { List, Datagrid, TextField, ReferenceField, FunctionField } from 'react-admin';

// import useModal from '../../../hooks/useModal';
// import ModalExport from '../../../Components/ModalExport';
import useAccessRole from '../../../hooks/useAccessRole';
import AccountBankCustom from '../CustomComponents/AccountBankCustom';
import { flagsCountriesName } from '../../../Config/constants';
import { TransactionFilter } from '../../Transactions/Filters';
import { MoneyTransactionField, Pagination } from '../../../Components';
import { AccountBank } from '../CustomComponents';
import PayoutsButtons from "../CustomComponents/PayoutsButtons";
import CountryProvider from "../../../Providers/Countries";
import ShowTransaction from '../CustomComponents/ShowTransaction';
import { useAlerts } from '../../../hooks';

const WithdrawalList = (props) => {

	const { isAccess } = useAccessRole();
	const { successMessage } = useAlerts();

	// const { open, showModal, hiddeModal } = useModal();

	const [transferCountries, setTransferCountries] = useState([]);

	useEffect(() => {
		(async () => {
			try {
				const response = await CountryProvider.fetchCountries();
				const transferTx = [];
				response.data.data.map((country) => {
					if (country.attributes.is_transfer && country.attributes.iso_code.toLowerCase() !== 'cl') {
						transferTx.push({
							id: country.id,
							...country.attributes,
						});
					}
				});

				setTransferCountries(transferTx);
			} catch { }
		})();

		return () => { }
	}, []);

	const isTransferCountry = useCallback((isoCode) => {
		let isTransfer = false;

		transferCountries.map((country) => {
			if (country?.iso_code?.toUpperCase() === isoCode?.toUpperCase()) {
				isTransfer = true;
			}
		});

		return isTransfer;
	}, [transferCountries]);

	const AccountBankComponent = (propsList) => {
		const { record } = propsList;
		const {
			id,
			attributes: {
				account_bank,
				sender_user,
				description,
				sender_email,
				is_dlocal,
				beneficiary,
				active_alerts,
				alert_count,
				decimals_destination,
				fast_payouts_provider,
				sender,
			}
		} = record;

		if (account_bank)
			return (
				<AccountBankCustom
					propsList={propsList}
					{...account_bank}
					sender_user={sender_user}
					transaction_id={id}
					is_dlocal={is_dlocal}
					sender_email={sender_email}
					description={description}
					country={flagsCountriesName[account_bank.iso_code_country]}
					beneficiary={beneficiary}
					active_alerts={active_alerts}
					alert_count={alert_count}
					decimals_destination={decimals_destination}
					copyValues={copyValues}
					fast_payouts_provider={fast_payouts_provider}
					sender={sender?.data?.attributes}
				/>
			);

		return (
			<ReferenceField
				{...propsList}
				label={'Cliente'}
				source={'attributes.sender_id'}
				reference={'users'}
				linkType={false}
			>
				<AccountBank transaction={record} />
			</ReferenceField >
		);
	};

	const IdColumn = (itemProps) => {
		const locking_status = itemProps.record.attributes.locking_status;
		return (
			<TextField
				{...itemProps}
				label={'Id'}
				className={locking_status == 'unlocked' ? '' : locking_status}
				onClick={() => copyValues(itemProps.record.id)}
			/>
		)
	};

	const MakeWithdrawalButtonReference = (propsList) => {
		const { record } = propsList;

		if (record?.attributes?.status !== 'pending') return null;

		return (
			<PayoutsButtons
				transaction={record}
				isTransferCountry={isTransferCountry}
			/>
		);
	};

	const copyValues = async (value) => {
		await navigator.clipboard.writeText(value);
		successMessage(`Valor copiado ${value}`)
	}

	return (
		<>
			<List
				{...props}
				title={'Lista de retiros'}
				filters={<TransactionFilter isWithdrawal />}
				exporter={isAccess('download_withdrawal') ? () => { } : false}
				pagination={<Pagination />}
				bulkActionButtons={false}
				debounce={1000}
			>
				<Datagrid classes={{ table: 'col_alert_1' }}>
					<IdColumn source={'id'} />
					<TextField
						source={'attributes.alt_id'}
						label={'Id público'}
					/>
					<ShowTransaction {...props} />
					<FunctionField
						label={'Fecha'}
						render={(record) => {
							moment.locale('es');
							const date = moment.utc(record.attributes.created_at).format('LLLL');
							return `${date} UTC`;
						}}
					/>
					<AccountBankComponent {...props} />
					<MoneyTransactionField
						attribute={'total_fee'}
						label={'Comisión'}
						isLabel={false}
					/>
					<MoneyTransactionField
						attribute={'amount'}
						label={'Monto'}
						isLabel={false}
					/>
					<MoneyTransactionField
						attribute={'total'}
						label={'Total'}
						isLabel={false}
					/>
					<MakeWithdrawalButtonReference {...props} />
				</Datagrid>
			</List>
			{/* <ModalExport
				open={open}
				hiddeModal={hiddeModal}
			/> */}
		</>
	);
};

export default WithdrawalList;
