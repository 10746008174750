import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
  Avatar,
  Checkbox,
} from "@material-ui/core";

import { COLUMS } from "../utils";
import EditProvider from "../EditProvider";

const Recharge = (props) => (
  <>
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            {COLUMS.map((colum, index) => {
              return <TableCell key={index}>{colum}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.providersList.map((item) => {
            return (
              <TableRow key={item.id}>
                <TableCell> {item.id} </TableCell>
                <TableCell> {item.order} </TableCell>
                <TableCell> {item.name} </TableCell>
                <TableCell> {item.public_name} </TableCell>
                <TableCell>
                  <Checkbox
                    checked={item.is_form}
                    inputProps={{ "aria-label": "Checkbox A" }}
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={item.is_trusted}
                    inputProps={{ "aria-label": "Checkbox A" }}
                  />
                </TableCell>
                <TableCell> {item.bank_information && JSON.stringify(item.bank_information)} </TableCell>
                <TableCell> {item.arrive_message} </TableCell>
                <TableCell> {item.commission_country.commission} </TableCell>
                <TableCell>{item.commission_country.commission_type}</TableCell>
                <TableCell> {item.commission_global.commission} </TableCell>
                <TableCell>{item.commission_global.commission_type}</TableCell>
                <TableCell>
                  {item.status_global_commission ? "Global" : "Local"}
                </TableCell>
                <TableCell> {item.is_active ? "Activo" : "Inactivo"}</TableCell>
                <TableCell>
                  <Avatar
                    alt='Error image'
                    src={item.recharge_provider_image}
                    style={{ borderRadius: 0, width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={item.is_auto_recharge}
                    inputProps={{ "aria-label": "Checkbox A" }}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => props.handleOpenModal(item)}
                    data-testid='edit-button'
                  >
                    Editar
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
    <EditProvider
      handleCloseModal={props.handleCloseModal}
      isOpenModal={props.isOpenModal}
      itemUpdate={props.itemUpdate}
      showImage={props.showImage}
      image_design={props.image_design}
      setImage_design={props.setImage_design}
      handleOnchange={props.handleOnchange}
      handleUpdateProvider={props.handleUpdateProvider}
      isUpdate={props.isUpdate}
      isGlobal={props.isGlobal}
    />
  </>
);
Recharge.propTypes = {
  providersList: PropTypes.array,
  COLUMS: PropTypes.array,
  handleCloseModal: PropTypes.func,
  showImage: PropTypes.func,
  handleOnchange: PropTypes.func,
  handleUpdateProvider: PropTypes.func,
  setImage_design: PropTypes.func,
  isOpenModal: PropTypes.bool,
  isUpdate: PropTypes.bool,
  image_design: PropTypes.array,
  itemUpdate: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    public_name: PropTypes.string,
    is_form: PropTypes.bool,
    bank_information: PropTypes.object,
    arrive_message: PropTypes.string,
    commission_country: PropTypes.shape({
      commission: PropTypes.string,
      commission_type: PropTypes.string,
    }),
    commission_global: PropTypes.shape({
      commission: PropTypes.string,
      commission_type: PropTypes.string,
    }),
    status_global_commission: PropTypes.bool,
    is_active: PropTypes.bool,
    recharge_provider_image: PropTypes.string,
  }),
};

Recharge.defaultProps = {
  providersList: [],
  COLUMS: [],
  showImage: () => { },
  handleCloseModal: () => { },
  handleOnchange: () => { },
  handleUpdateProvider: () => { },
  setImage_design: () => { },
  isOpenModal: false,
  image_design: [],
  itemUpdate: {
    id: 1,
    name: '',
    public_name: '',
    is_form: false,
    bank_information: {},
    arrive_message: '',
    commission_country: {
      commission: '1',
      commission_type: 'value',
    },
    commission_global: {
      commission: '1',
      commission_type: 'value',
    },
    status_global_commission: true,
    is_active: true,
    recharge_provider_image: '',
  },
};

export default Recharge;
