import moment from "moment";

const responseHandler = (response, type) => {

  let result = [];

  if (response.data.transactions.data && response.data.transactions.data.length > 0) {
    result =response.data.transactions.data.map((transaction) => {
      return {
        id: transaction.id,
        type: transaction.type,
        ...transaction.attributes,
        wallet_destination: transaction.attributes.wallet_destination.wallet_name,
        wallet_origin: transaction.attributes.wallet_origin.wallet_name,
        created_at: transaction.attributes.created_at = `${moment
        .utc(transaction.attributes.created_at)
        .format("L HH:mm:ss")} UTC`
      }
    });
  }
  

  switch (type) {    
    default: {
      return {
        data: result,
        total: response.data['total'],
      }
    }
  };
}

export default responseHandler;
