import React from 'react';
import moment from 'moment';
import { FileField } from 'react-admin';
import { Grid, TextField } from '@material-ui/core';

import { DOCUMENT_TYPE } from "../../../../../Utils";

import { civilStatus } from "../../../../../Config/constants";

import styles from "./styles";

const NaturalUserData = (props) => {
  const { record } = props;
  const {
    id,
    attributes: {
      city,
      address,
      default_currency,
      email,
      document_number,
      secondary_document_number,
      document_type,
      secondary_document_type,
      document_expedition_date,
      document_image_url,
      document_issuing_country_name,
      civil_status,
      ocupation,
      phone,
      residence_country_name,
      state_name,
      document_expiry_at
    },
    country,
  } = record;

  const renderDocumentType = (value) => DOCUMENT_TYPE.find((e) => e.id === value)?.name || '---';

  return (
    <Grid container direction="row" justify="center" alignItems="center" style={styles.divisorTop}>
      <Grid style={styles.divisor} item xs={12} sm={5}>
        <TextField
          label={"Id usuario"}
          value={id}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid
        style={styles.divisor}
        item
        xs={12}
        sm={5}
      >
        <TextField
          value={country?.attributes?.name || '---'}
          label={'País de Residencia'}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid
        style={styles.divisor}
        item
        xs={12}
        sm={5}
      >
        <TextField
          value={state_name || '---'}
          label={'Estado/Provincia'}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
        />
        </Grid>
        
        <Grid
        style={styles.divisor}
        item
        xs={12}
        sm={5}
        >
            <TextField
            value={city || '---'}
            label={'Ciudad'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            value={address || '---'}
            label={'Dirección'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            value={default_currency || '---'}
            label={'Moneda'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            value={email || '---'}
            label={'Correo electrónico'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            value={document_issuing_country_name || '---'}
            label={'País emisor del documento'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            value={document_number || '---'}
            label={'Número de documento'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            value={renderDocumentType(document_type)}
            label={'Tipo de documento'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            value={secondary_document_number || '---'}
            label={country?.attributes?.name === 'Brasil' ? 'Número de documento secundario / CPF' : 'Número de documento secundario'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            value={renderDocumentType(secondary_document_type?.slice(2) || '')}
            label={'Tipo de documento secundario'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            value={document_expedition_date ? moment(document_expedition_date).format('LL') : '---'}
            label={'Fecha de expedición del documento'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            value={document_expiry_at ? moment(document_expiry_at).format('LL') : '---'}
            label={'Fecha de expiración del documento'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'Estado civil'}
            value={civilStatus[civil_status] || '---'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'Teléfono'}
            value={phone || '---'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'Ocupación'}
            value={ocupation || '---'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid 
            style={styles.divisor} 
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'País de nacimiento'}
            value={residence_country_name || '---'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <FileField
            value={document_image_url}
            target={'_blank'}
            title={'Documento'}
            label={'Documento'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
    </Grid>
  );
};

NaturalUserData.defaultProps = {
  record: {
    id: '',
    attributes: {
      first_name: '',
      last_name: '',
      birthdate: '',
      password_last_changed_at: '',
      last_sign_in_at: '',
      city: '',
      address: '',
      email_confirmed: '',
      default_currency: '',
      email: '',
      document_number: '',
      document_expedition_date: '',
      auto_convert_to_local_currency: '',
      document_type: '',
      accept_funds: '',
      accept_pep: '',
      profile_completed: '',
      second_factor: '',
      accept_real_info: '',
      automatic_verification: '',
      biometric_verification: '',
      document_image_url: '',
      profile_verification: '',
      account_bank: {
        bank: '',
        type_account_bank: '',
        account_bank: '',
      },
    },
    country: {
      attributes: {
        name: '',
      },
    },
  }
};

export default NaturalUserData;