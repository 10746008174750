import React, { useState } from "react";
import moment from "moment";
import { Animated } from "react-animated-css";
import { Button, TextField, Switch, FormControlLabel } from "@material-ui/core";

import styles from "../../../Withdrawals/CustomComponents/MakeWithdrawalButton/styles";
import Images from "../../../../Assets/images";
import UsdcWithdrawalsProvider from "../../../../Providers/UsdcWithdrawals";
import { toMoney } from "../../../../Helpers/MoneyHelper";
import { useAccessRole, useReset } from "../../../../hooks";
import TransactionProvider from "../../../../Providers/Transactions";
import Swal from 'sweetalert2';

moment.locale("es");
const CircleInfo = (props) => {
  const {
    record: {
      id,
      attributes: {
        is_locked,
        created_at,
        amount,
        to_address,
        description,
        total,
        total_fee,
        network,
        sender_user: {
          data: {
            attributes: { first_name, last_name, email },
          },
        },
      },
    },
  } = props;
  const [isAutomaticWithdrawal, setAutomaticWithdrawal] = useState(false);
  const [isCompleteWitdrawal, setCompleteWithdrawal] = useState(false);
  const [isRejectWithdrawal, setRejectWithdrawal] = useState(false);
  const [isFetch, setFetch] = useState();
  const [isError, setError] = useState(false);
  const [error, setE] = useState("");
  const [hash, setHash] = useState("");
  const { resetList } = useReset();
  const { isAccess } = useAccessRole();

  const handleChangeLockedStatus = async () => {
    Swal.showLoading();
    try {
      await TransactionProvider.updateLockedStatus(id, { is_locked: !is_locked });
      resetList();
      Swal.close();
    } catch (error) {
      Swal.close();
      Swal.fire(
        'Lo sentimos, algo no fue bien',
        'No fue posible cambiar el estado de bloqueo de este registro.',
        'error',
      )
    }
  }

  const upLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const changeHash = (e) => {
    setHash(e.target.value);
  };

  const automaticWithdrawal = async () => {
    try {
      setFetch(true);
      await UsdcWithdrawalsProvider.automaticWithdrawal({
        transaction_id: id,
      });

      resetList();
    } catch (e) {
      setE(JSON.stringify(e.data.data.message));
      setError(true);
    }
    setFetch(false);
  };

  const completeWithdrawal = async () => {
    try {
      setFetch(true);
      await UsdcWithdrawalsProvider.completeWithdrawal({ id, hash });
      resetList();
    } catch (e) {
      setE(e.data.data);
      setError(true);
    }
    setFetch(false);
  };

  const rejectWithdrawal = async () => {
    try {
      setFetch(true);
      await UsdcWithdrawalsProvider.rejectWithdrawal({ id });
      resetList();
    } catch (e) {
      setE(e.data.message ? e.data.message : JSON.stringify(e.data.data));
      setError(true);
    }
    setFetch(false);
  };

  return (
    <div style={{ width: "100%"}}>
      <div
        style={{
          width: "100%",
          maxWidth: 300,
          padding: 20,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          Envío
          <div style={{ fontSize: 18, fontWeight: "bold" }}>{id}</div>
        </div>
        <div>
          <div>{moment.utc(created_at).format("LLLL")}</div>
          <div style={{ fontSize: 25, fontWeight: "bold", color: "#00d9ff" }}>
            {toMoney(amount, "USDC")} USDC
          </div>
        </div>
      </div>
      <div
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingRight: 50,
              justifyContent: "flex-start",
            }}
          >
            <div style={{ fontSize: 20, fontWeight: "bold" }}>
              {first_name ? upLetter(first_name) : ""}{" "}
              {last_name ? upLetter(last_name) : ""}
            </div>
            <div style={{ fontSize: 12 }}>{email}</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingRight: 50,
              justifyContent: "flex-start",
            }}
          >
            <div style={{ fontSize: 20, fontWeight: "bold" }}>
              {"Descripción"}
            </div>
            <div style={{ fontSize: 12 }}>{description}</div>
            <div style={{ fontSize: 20, fontWeight: "bold", marginTop: 24 }}>
              {"Red"}
            </div>
            <div style={{ fontSize: 12 }}>{network}</div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                width: 120,
                height: 120,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              {
                isAccess('edit_transaction_locked_status') && (<FormControlLabel
                  control={
                    <Switch checked={is_locked} onChange={handleChangeLockedStatus} />
                  }
                  label={is_locked ? 'Desbloquear' : 'Bloquear'}
                />)
              }
              {
                useAccessRole('crypto_complete_withdrawal') &&
                <Button
                  disabled={is_locked}
                  onClick={() => setCompleteWithdrawal(true)}
                  style={ is_locked ? { ...styles.buttonDisabled, fontSize: 12 } : { ...styles.buttonBlue, fontSize: 12 }}
                >
                  MARCAR COMPLETADA
                </Button>
              }
              {
                useAccessRole('crypto_automatic_withdrawal') &&
                <Button
                  disabled={is_locked}
                  onClick={() => setAutomaticWithdrawal(true)}
                  style={is_locked ? {
                    ...styles.buttonDisabled,
                    marginTop: 5,
                    marginBottom: 5,
                    fontSize: 12,
                  } : {
                    ...styles.buttonBlue,
                    marginTop: 5,
                    marginBottom: 5,
                    fontSize: 12,
                  }}
                >
                  PAGO AUTOMÁTICO
                </Button>
              }
              {
                useAccessRole('crypto_reject_withdrawal') &&
                <Button
                  onClick={() => setRejectWithdrawal(true)}
                  style={{ ...styles.buttonBlue, fontSize: 12, width: "100%" }}
                >
                  RECHAZAR
                </Button>
              }
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingRight: 50,
              justifyContent: "flex-start",
            }}
          >
            <div style={{ fontSize: 20, fontWeight: "bold" }}>{"Total"}</div>
            <div style={{ fontSize: 12 }}>{toMoney(total, "USDC")}</div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingRight: 50,
              justifyContent: "flex-start",
            }}
          >
            <div style={{ fontSize: 20, fontWeight: "bold" }}>
              {"Total fee"}
            </div>
            <div style={{ fontSize: 12 }}>{toMoney(total_fee, "USDC")}</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingRight: 50,
              justifyContent: "flex-start",
            }}
          >
            <div style={{ fontSize: 20, fontWeight: "bold" }}></div>
            <div style={{ fontSize: 12 }}></div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          paddingTop: 5,
          fontSize: 15,
          textAlign: "center",
        }}
      >
        Dirección
      </div>
      {to_address && (
        <div
          style={{
            width: "100%",
            padding: 5,
            paddingBottom: 15,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ padding: 5 }}>
            <div
              style={{
                fontSize: 12,
                padding: 10,
                textAlign: "center",
                borderRadius: 5,
                width: 250,
                backgroundColor: "#00b7ff",
              }}
            >
              {to_address}
            </div>
          </div>
        </div>
      )}
      {isAutomaticWithdrawal && (
        <div
          style={{
            width: "100%",
            position: "relative",
            top: -300,
            backgroundColor: "rgba(0,0,0,.78)",
            height: 300,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isFetch ? (
            <Animated
              animationIn="fadeIn"
              animationOut="fadeOut"
              isVisible={true}
            >
              <Animated
                style={{ animationIterationCount: "infinite" }}
                animationIn="jello"
                animationOut="fadeOut"
                isVisible={true}
              >
                <img src={Images.vita} width={60} height={60} />
              </Animated>
            </Animated>
          ) : isError ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ fontSize: 18, textAlign: "center" }}>
                Ha ocurrido un error, intente mas tarde
              </div>
              <div
                style={{
                  fontSize: 14,
                  paddingBottom: 20,
                  textAlign: "center",
                }}
              >
                {error}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={() => {
                    setError(false);
                    setAutomaticWithdrawal(false);
                  }}
                  style={styles.buttonRed}
                >
                  ATRAS
                </Button>
              </div>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                position: "relative",
                top: 50,
                backgroundColor: "rgba(0,0,0,.78)",
                height: 300,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: 18,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  ¿Está seguro de realizar esta operación?
                </div>
                <div
                  style={{
                    fontSize: 14,
                    paddingBottom: 20,
                    textAlign: "center",
                  }}
                >
                  la operación será irreversible
                </div>
                <div
                  style={{
                    width: 190,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    onClick={automaticWithdrawal}
                    style={styles.buttonBlue}
                  >
                    PAGAR
                  </Button>
                  <Button
                    onClick={() => setAutomaticWithdrawal(false)}
                    style={styles.buttonRed}
                  >
                    CANCELAR
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {isCompleteWitdrawal && (
        <div
          style={{
            width: "100%",
            position: "relative",
            top: -300,
            backgroundColor: "rgba(0,0,0,.78)",
            height: 300,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isFetch ? (
            <Animated
              animationIn="fadeIn"
              animationOut="fadeOut"
              isVisible={true}
            >
              <Animated
                style={{ animationIterationCount: "infinite" }}
                animationIn="jello"
                animationOut="fadeOut"
                isVisible={true}
              >
                <img src={Images.vita} width={60} height={60} />
              </Animated>
            </Animated>
          ) : isError ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ fontSize: 18, textAlign: "center" }}>
                Ha ocurrido un error, intente mas tarde
              </div>
              <div
                style={{
                  fontSize: 14,
                  paddingBottom: 20,
                  textAlign: "center",
                }}
              >
                {error}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={() => {
                    setError(false);
                    setCompleteWithdrawal(false);
                  }}
                  style={styles.buttonRed}
                >
                  ATRAS
                </Button>
              </div>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                position: "relative",
                top: 50,
                backgroundColor: "rgba(0,0,0,.78)",
                height: 300,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  HASH RESULTANTE
                </div>
                <div
                  style={{
                    fontSize: 14,
                    width: "100%",
                    maxWidth: 300,
                    paddingBottom: 20,
                    textAlign: "center",
                  }}
                >
                  Por favor ejecute la transacción manualmente y al finalizar
                  complete el hash resultante
                </div>
                <div
                  style={{ paddingBottom: 30, width: "100%", maxWidth: 500 }}
                >
                  <TextField
                    style={{ width: "100%" }}
                    value={hash}
                    onChange={changeHash}
                    placeholder={"hash"}
                  />
                </div>
                <div
                  style={{
                    width: 190,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    onClick={completeWithdrawal}
                    style={{ ...styles.buttonBlue, opacity: hash ? 1 : 0.5 }}
                    disabled={!hash}
                  >
                    PAGAR
                  </Button>
                  <Button
                    onClick={() => setCompleteWithdrawal(false)}
                    style={styles.buttonRed}
                  >
                    CANCELAR
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {isRejectWithdrawal && (
        <div
          style={{
            width: "100%",
            position: "relative",
            top: -300,
            backgroundColor: "rgba(0,0,0,.78)",
            height: 300,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isFetch ? (
            <Animated
              animationIn="fadeIn"
              animationOut="fadeOut"
              isVisible={true}
            >
              <Animated
                style={{ animationIterationCount: "infinite" }}
                animationIn="jello"
                animationOut="fadeOut"
                isVisible={true}
              >
                <img src={Images.vita} width={60} height={60} />
              </Animated>
            </Animated>
          ) : isError ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ fontSize: 18, textAlign: "center" }}>
                Ha ocurrido un error, intente mas tarde
              </div>
              <div
                style={{
                  fontSize: 14,
                  paddingBottom: 20,
                  textAlign: "center",
                }}
              >
                {error}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={() => {
                    setError(false);
                    setRejectWithdrawal(false);
                  }}
                  style={styles.buttonRed}
                >
                  ATRAS
                </Button>
              </div>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                position: "relative",
                top: 50,
                backgroundColor: "rgba(0,0,0,.78)",
                height: 300,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: 18,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  ¿Está seguro de realizar esta operación?
                </div>
                <div
                  style={{
                    fontSize: 14,
                    paddingBottom: 20,
                    textAlign: "center",
                  }}
                >
                  la operación será irreversible
                </div>
                <div
                  style={{
                    width: 190,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    onClick={rejectWithdrawal}
                    style={{ ...styles.buttonBlue, marginRight: 5 }}
                  >
                    CONFIRMAR
                  </Button>
                  <Button
                    onClick={() => setRejectWithdrawal(false)}
                    style={styles.buttonRed}
                  >
                    CANCELAR
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CircleInfo;
