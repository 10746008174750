import React, { useEffect, useState } from 'react';
import { Edit, FormTab, SelectInput, TabbedForm, TextInput, Toolbar, SaveButton, FormDataConsumer, DateInput } from 'react-admin';
import { Col, Container, Row } from 'react-grid-system';
import TransactionProvider from '../../../Providers/Transactions';

import useBanksCountry from '../../../hooks/useBanksCountry';
import useAccessRole from '../../../hooks/useAccessRole';

import { CreateComment, ListComment } from '../../../Components/comments';
import { CATEGORY, DOCUMENT_TYPE } from '../../../Utils';

const TransactionEdit = (props) => {
	const [documentType, setDocumentType] = useState([]);
	const [accountType, setAccountType] = useState([]);

	const { isAccess } = useAccessRole();
	const BankAccount = ({ record }) => {
		const {
			attributes: {
				account_bank: {
					account_bank: {
						bank_id,
						bank,
						bank_branch,
						transfer_type,
						type_account_bank
					},
					iso_code_country,
					category,
					document_type,
				},
			},
		} = record;

		const { banks } = useBanksCountry(iso_code_country, bank_id, bank, category);

		const getOptions = (fields, field) => {
			const f = fields.find(f => (f.name_key === field));
			return f?.options || []
		}

		const getTransferRules = async () => {
			const resp = await TransactionProvider.getTransferRules();

			resp.data[iso_code_country.toLowerCase()].rules.forEach((section) => {
				if (section.label === 'Documento de identidad')
					setDocumentType(getOptions(section.fields, 'document_type'));

				if (section.label === 'Datos bancarios')
					setAccountType(getOptions(section.fields, 'account_type_bank'));
			});
		}

		useEffect(() => {
			if (documentType.length === 0)
				getTransferRules();
		}, [])


		return (
			<Container style={{ width: '100%' }}>
				<Row container direction="row" justify="center" alignItems="center" className={{ flexGrow: 1 }}>
					<Col xs={3} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
						<TextInput
							label="Código ISO del país"
							source="attributes.account_bank.iso_code_country"
							disabled
						/>
					</Col>
					<Col xs={3} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
						<TextInput
							label="Moneda"
							source="attributes.account_bank.currency"
							disabled
						/>
					</Col>
					<Col xs={3} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
						<TextInput
							label="Nombres"
							source="attributes.account_bank.first_name"
						/>
					</Col>
					<Col xs={3} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
						<TextInput
							label="Apellidos"
							source="attributes.account_bank.last_name"
						/>
					</Col>
					<Col xs={3} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
						<TextInput
							label="Número de documento"
							source="attributes.account_bank.document_number"
						/>
					</Col>
					<Col xs={3} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
						<SelectInput
							native
							value={document_type}
							label="Tipo de documento"
							source="attributes.account_bank.document_type"
							choices={documentType}
							optionText="label"
							optionValue="value"
							disabled={documentType.length === 0}
						/>
					</Col>
					<Col xs={3} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
						<TextInput
							label="Dirección de residencia"
							source="attributes.account_bank.address"
						/>
					</Col>
					<Col xs={3} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
						<TextInput
							label="Correo"
							source="attributes.account_bank.email"
						/>
					</Col>
					<Col xs={3} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
						<TextInput
							label="Correo del negocio"
							source="attributes.account_bank.email_business"
						/>
					</Col>
					<Col xs={3} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
						<TextInput
							label="Número de orden"
							source="attributes.account_bank.order"
						/>
					</Col>
					{
						banks.length > 0 && (
							<Col xs={3} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
								<SelectInput
									native
									value={bank_id}
									label="Id del banco"
									source="attributes.account_bank.account_bank.bank_id"
									choices={banks}
								/>
							</Col>
						)
					}
					{bank_branch !== undefined && (
						<Col xs={3} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
							<TextInput
								label="Sucursal bancaria"
								source="attributes.account_bank.account_bank.bank_branch"
							/>
						</Col>
					)
					}
					<Col xs={3} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
						<TextInput
							label="Código"
							source="attributes.account_bank.account_bank.code"
							disabled
						/>
					</Col>
					<Col xs={3} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
						<SelectInput
							native
							value={type_account_bank}
							label="Tipo de cuenta"
							source="attributes.account_bank.account_bank.type_account_bank"
							choices={accountType}
							optionText="label"
							optionValue="value"
							disabled={accountType.length === 0}
						/>
					</Col>
					<Col xs={3} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
						{
							transfer_type === 'Pago movil'
								? <TextInput
									label="Pago móvil"
									source="attributes.account_bank.account_bank.associate_phone"
								/>
								: <TextInput
									label="Número de cuenta"
									source="attributes.account_bank.account_bank.account_bank"
								/>
						}
					</Col>
					<Col xs={3} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
						<TextInput
							label="Número de ruta"
							source="attributes.account_bank.account_bank.routing_number"
						/>
					</Col>
					<Col xs={3} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
						<TextInput
							label="Nombre de la compañia"
							source="attributes.account_bank.account_bank.company_name"
						/>
					</Col>
					<Col xs={3} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
						<TextInput
							label="Tipo de beneficiario"
							source="attributes.account_bank.account_bank.beneficiary_type"
						/>
					</Col>
					<Col xs={3} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
						<TextInput
							label="Ciudad"
							source="attributes.account_bank.city"
						/>
					</Col>
					<Col xs={3} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
						<TextInput
							label="Número Swift"
							source="attributes.account_bank.account_bank.swift_bic"
						/>
					</Col>
					<Col xs={3} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
						<TextInput
							label="Comentario"
							source="attributes.purpose_comentary"
						/>
					</Col>
				</Row>
			</Container>
		);
	};

	const BankAccountHOC = (propsHoc) => {
		const {
			attributes: {
				account_bank
			},
		} = propsHoc.record;

		if (account_bank.hasOwnProperty('account_bank')) {
			return <BankAccount {...propsHoc} />;
		}

		return (
			<div style={{ color: '#FFFFFF', padding: 20 }}>
				No aplica
			</div>
		);
	};

	const FinalCustomerEditPanel = (props) => {
		const {
			record: {
				attributes: {
					final_customer: finalCustomer = {}
				} = {}
			} = {}
		} = props;
		const [customerType, setCustomerType] = useState(finalCustomer?.customer_type);

		return <Container style={{ width: '100%' }}>
			<Row container direction="row" justify="center" alignItems="center" className={{ flexGrow: 1 }}>
				<Col xs={3} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
					<SelectInput
						native
						value={customerType}
						label="Tipo de cliente"
						source="attributes.final_customer.customer_type"
						choices={CATEGORY}
						optionText="name"
						optionValue="id"
						onChange={(event, key, payload) => setCustomerType(key)}
					/>
				</Col>
				<Col xs={3} style={{ padding: 5, display: (customerType === 'natural' ? 'flex' : 'none'), textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
					<TextInput
						label="Nombre completo"
						source="attributes.final_customer.legal_name"
					/>
				</Col>
				<Col xs={3} style={{ padding: 5, display: (customerType === 'natural' ? 'flex' : 'none'), textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
					<SelectInput
						native
						label="Tipo de documento de identidad"
						source="attributes.final_customer.document_type"
						choices={DOCUMENT_TYPE.filter((e) => ['dni', 'passport', 'company_id'].includes(e.id))}
						optionText="name"
						optionValue="id"
					/>
				</Col>
				<Col xs={3} style={{ padding: 5, display: (customerType === 'natural' ? 'flex' : 'none'), textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
					<TextInput
						label="Número de documento"
						source="attributes.final_customer.document_number"
					/>
				</Col>
				<Col xs={3} style={{ padding: 5, display: (customerType === 'natural' ? 'flex' : 'none'), textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
					<DateInput
						label="Fecha de nacimiento"
						source="attributes.final_customer.birthdate"
					/>
				</Col>
				<Col xs={3} style={{ padding: 5, display: (customerType === 'natural' ? 'flex' : 'none'), textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
					<TextInput
						label="Domicilio completo"
						source="attributes.final_customer.legal_address"
					/>
				</Col>

				<Col xs={3} style={{ padding: 5, display: (customerType === 'business' ? 'flex' : 'none'), textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
					<TextInput
						label="Razón social"
						source="attributes.final_customer.legal_name"
					/>
				</Col>
				<Col xs={3} style={{ padding: 5, display: (customerType === 'business' ? 'flex' : 'none'), textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
					<TextInput
						label="Nombre fantasía"
						source="attributes.final_customer.trade_name"
					/>
				</Col>
				<Col xs={3} style={{ padding: 5, display: (customerType === 'business' ? 'flex' : 'none'), textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
					<TextInput
						label="Número de TAX ID"
						source="attributes.final_customer.tax_id"
					/>
				</Col>
				<Col xs={3} style={{ padding: 5, display: (customerType === 'business' ? 'flex' : 'none'), textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
					<TextInput
						label="Domicilio completo"
						source="attributes.final_customer.legal_address"
					/>
				</Col>
			</Row>
		</Container>
	}

	const Comments = (props) => {
		return (
			<div>
				<FormDataConsumer>
					{
						({ formData, ...rest }) => (
							<CreateComment
								{...formData}
								{...rest}
								service={TransactionProvider.postTransactionComments}
							/>
						)
					}
				</FormDataConsumer>
				<ListComment
					{...props}
					service={TransactionProvider.getTransactionComments}
				/>
			</div>
		);
	};

	const UserEditToolbar = props => {
		const [commentSelected, setCommentSelected] = useState(false);

		useEffect(() => {
			const tab = document.getElementById('comment_tab');

			if (tab) setCommentSelected(tab.getAttribute('aria-selected') === 'true');
		}, []);

		return (
			<Toolbar {...props} >
				{
					!commentSelected && (
						<SaveButton />
					)
				}
			</Toolbar>
		)
	};

	return (
		<Edit
			title={`Editar transacción ${props.id}`}
			{...props}
		>
			<TabbedForm toolbar={<UserEditToolbar />}>
				<FormTab label="Datos" style={{ color: '#FFFFFF' }}>
					<Container style={{ width: '100%' }}>
						<Row container direction="row" justify="center" alignItems="center" className={{ flexGrow: 1 }}>
							<Col xs={3} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
								<SelectInput label="Estado" source="attributes.status" choices={[
									{ id: 'started', name: 'Iniciada' },
									{ id: 'completed', name: 'Completada' },
									{ id: 'pending', name: 'Pendiente' },
									{ id: 'denied', name: 'Rechazada' },
									{ id: 'processed', name: 'Procesada' },
									{ id: 'failed', name: 'Fallida' }
								]} />
							</Col>
							<Col xs={7} style={{ padding: 5, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
								<TextInput
									label={`Descripción del cambio (Visible para el usuario)`}
									source="attributes.description_state_change"
									fullWidth
								/>
							</Col>
						</Row>
					</Container>
				</FormTab>
				<FormTab label="Cuenta bancaria" style={{ color: '#FFFFFF' }}>
					<BankAccountHOC />
				</FormTab>
				<FormTab label="Cliente final" style={{ color: '#FFFFFF' }}>
					<FinalCustomerEditPanel />
				</FormTab>
				{
					isAccess('create_transaction_comments') && (
						<FormTab label="Comentarios" style={{ color: '#FFFFFF' }} id={'comment_tab'}>
							<Comments />
						</FormTab>
					)
				}
			</TabbedForm>
		</Edit>
	);
};

export default TransactionEdit;
