import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { 
  Modal, 
  Card,
  Typography, 
  Grid, 
  InputLabel, 
  Select, 
  MenuItem, 
  Button, 
  FormControl,
  TextField
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import styles from '../styles';

const WalletDestinationModal = memo((props) => (
  <Modal
    open={props.isOpenModal}
    style={styles.modalContainer}
    onClose={props.closeModal}
  >
    <Card style={{ ...styles.cardContainer, width: 536 }}>
      <div style={styles.closeButtonContainer}>
        <Close style={styles.closeButton} onClick={props.closeModal} />
      </div>
      <Typography variant="title" style={styles.title}>
        {!props.isUpdate ? "Nueva" : "Editar"} wallet destino
      </Typography>
      <form style={styles.formContainer}>
        <Grid container>
          <TextField
            value={props.formData.walletName}
            onChange={props.handleChange}
            name="walletName"
            label="Nombre de la wallet"
            placeholder="Nombre de la wallet"
            style={styles.textField}
            margin="normal"
          />
        </Grid>
        <Typography
          variant="title"
          style={{
            ...styles.subtitle,
            marginTop: 32,
            marginBottom: 8,
          }}
        >
          Información técnica
        </Typography>
        <Grid container direction="column">
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
          >
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Typography
                  variant="body1"
                  style={{ ...styles.body, marginTop: 20 }}
                >
                  Criptomoneda a retirar
                </Typography>
                <FormControl style={styles.smallSelectContainer}>
                  <InputLabel
                    htmlFor="whitdrawal-funds-modal-crypto-select"
                    style={styles.body}
                  >
                    Selecciona la moneda
                  </InputLabel>
                  <Select
                    value={props.formData.currency}
                    onChange={props.handleChange}
                    style={styles.smallSelect}
                    name="currency"
                    id="whitdrawal-funds-modal-crypto-select"
                    disabled={props.isUpdate}
                    inputProps={{
                      name: "currency",
                      id: "whitdrawal-funds-modal-crypto-select",
                    }}
                  >
                    {props.currenciesOptions &&
                      props.currenciesOptions.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Typography
                  variant="body1"
                  style={{ ...styles.body, marginTop: 20 }}
                >
                  Red
                </Typography>
                <FormControl style={styles.smallSelectContainer}>
                  <InputLabel
                    htmlFor="whitdrawal-funds-modal-crypto-select"
                    style={styles.body}
                  >
                    Selecciona la red
                  </InputLabel>
                  <Select
                    value={props.formData.network}
                    onChange={props.handleChange}
                    style={styles.smallSelect}
                    name="network"
                    id="whitdrawal-funds-modal-crypto-select"
                    disabled={props.isUpdate}
                    inputProps={{
                      name: "network",
                      id: "whitdrawal-funds-modal-crypto-select",
                    }}
                  >
                    {props.networkSettings &&
                      props.networkSettings.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <TextField
              value={props.formData.walletDirection}
              onChange={props.handleChange}
              name="walletDirection"
              label="Dirección de wallet"
              placeholder="Dirección de wallet"
              style={styles.textField}
              margin="normal"
              disabled={props.isUpdate}
            />
          </Grid>
          <Grid container>
            <TextField
              value={props.formData.description}
              onChange={props.handleChange}
              name="description"
              label="Descripción (opcional)"
              placeholder="Descripción (opcional)"
              style={{ ...styles.textField }}
              margin="normal"
            />
          </Grid>
        </Grid>
      </form>
      <Grid container direction="row" spacing={32} justify="center">
        <Grid item>
          <Button style={styles.cancelButton} onClick={props.closeModal}>
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={props.isValid ? styles.submitButton : styles.disabledButton}
            disabled={!props.isValid}
            onClick={props.onSubmit}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </Card>
  </Modal>
));

WalletDestinationModal.PropTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired
};

export default WalletDestinationModal;