import { AttachMoney } from '@material-ui/icons';
import {WalletList} from '..'
import { WALLET_TRANSACTIONS } from '../utils';
import { useAccessRole } from '../../../../hooks';

const { isAccess } = useAccessRole();

export default {
  name: WALLET_TRANSACTIONS,
  icon: AttachMoney,
  list: isAccess('get_wallet_transactions') ? WalletList : false,
};