import { GET_MANY, GET_ONE, UPDATE } from 'react-admin';

import { RESOURCES } from '../../Helpers/ResourceHelper';

import {
    userRestHandler,
    configurationRestHandler,
    dlocalRestHandler,
    roleRestHandler,
    adminRestHandler,
    fintocRestHandler,
    pricesRestHandler,
    settingsHeaderRestHandler,
    businessRestHandler,
    MarketingMessageRestHandler,
    WithdrawalsRestHandler,
    TransactionsStatusRestHandler,
    categoriesRestHandler,
    RequestsRestHandler,
    BankMovementsHandler,
    BankAccountAccesses,
    CardAccountsHandler,
    SegmentsRestHandler,
    CouponsRestHandler,
    BinanceNetworksRestHandler,
    FortressRestHandler,
    BannersRestHandler,
    SnackbarsRestHandler,
    VitaPricesHandler,
    CustodyErrorCryptoWalletsRestHandler,
    MaintenanceChargesRestHandler,
    CryptoPayoutProvidersRestHandler,
    CustodyWalletsRestHandler,
    CustodyWalletsTransactionsRestHandler,
} from './handlers';

const responseHandler = (response, type, resource, params) => {
    switch (resource) {
        case RESOURCES.vita_prices: {
            return VitaPricesHandler.responseHandler(response, type);
        }
        case RESOURCES.fortress: {
            return FortressRestHandler.responseHandler(response, type);
        }
        case RESOURCES.bank_account_accesses: {
            return BankAccountAccesses.responseHandler(response, type);
        }
        case RESOURCES.requests: {
            return RequestsRestHandler.responseHandler(response, type);
        }
        case RESOURCES["estados de las transacciones"]: {
            return TransactionsStatusRestHandler.responseHandler(response, type, params);
        }
        case RESOURCES.prices: {
            return pricesRestHandler.responseHandler(response, type, params);
        }
        case RESOURCES.settingsHeaders: {
            return settingsHeaderRestHandler.responseHandler(response, type, params);
        }
        case RESOURCES.bank_movements: {
            return BankMovementsHandler.responseHandler(response, type, params);
        }
        case RESOURCES.fintoc: {
            return fintocRestHandler.responseHandler(response, type, params);
        }
        case RESOURCES.usuarios: {
            return userRestHandler.responseHandler(response, type);
        }
        case RESOURCES.configuraciones: {
            return configurationRestHandler.responseHandler(response, type, params);
        }
        case RESOURCES.dlocal: {
            return dlocalRestHandler.responseHandler(response, type, params);
        }
        case RESOURCES.roles: {
            return roleRestHandler.responseHandler(response, type, resource)
        }
        case RESOURCES.administrators: {
            return adminRestHandler.responseHandler(response, type, resource)
        }
        case RESOURCES.business_transactions: {
            return businessRestHandler.responseHandler(response, type, resource)
        }
        case RESOURCES.marketing_message: {
            return MarketingMessageRestHandler.responseHandler(response, type, resource)
        }
        case RESOURCES.retiros: {
            return WithdrawalsRestHandler.responseHandler(response, type, resource);
        }
        case RESOURCES.categories: {
            return categoriesRestHandler.responseHandler(response, type, resource);
        }
        case RESOURCES.card_accounts: {
            return CardAccountsHandler.responseHandler(response, type, resource);
        }
        case RESOURCES.segments: {
            return SegmentsRestHandler.responseHandler(response, type, resource);
        }
        case RESOURCES.coupons: {
            return CouponsRestHandler.responseHandler(response, type, resource);
        }
        case RESOURCES.couponsSegmentList: {
            return SegmentsRestHandler.responseHandler(response, type, resource);
        }
        case RESOURCES.segmentListUsers: {
            return SegmentsRestHandler.responseHandler(response, type, resource);
        }
        case RESOURCES.binanceNetworks: {
            return BinanceNetworksRestHandler.responseHandler(response, type, resource);
        }
        case RESOURCES.banners: {
            return BannersRestHandler.responseHandler(response, type, resource);
        }
        case RESOURCES.snackbars: {
            return SnackbarsRestHandler.responseHandler(response, type, resource);
        }
        case RESOURCES.custody_error_crypto_wallets: {
            return CustodyErrorCryptoWalletsRestHandler.responseHandler(response, type, resource);
        }
        case RESOURCES.maintenance_charges: {
            return MaintenanceChargesRestHandler.responseHandler(response, type, resource);
        }
        case RESOURCES.crypto_payout_providers: {
            return CryptoPayoutProvidersRestHandler.responseHandler(response, type, resource);
        }
        case RESOURCES.custody_wallets_origin: {
            return CustodyWalletsRestHandler.responseHandler(response, type, resource);
        }
        case RESOURCES.custody_wallets_destination: {
            return CustodyWalletsRestHandler.responseHandler(response, type, resource);
        }
        case RESOURCES.custody_wallets_transactions: {
            return CustodyWalletsTransactionsRestHandler.responseHandler(response, type, resource);
        }
        default: {
            switch (type) {
                case UPDATE: {
                    return {
                        data: response.data.data,
                    }
                }
                case GET_ONE: {
                    if (RESOURCES.negocios === resource) {
                        return {
                            data: {
                                id: 1,
                                ips: response.data.ips
                            },
                        };
                    }

                    const responseData = response.data[resource].data;

                    return {
                        data: responseData[0],
                    };
                }
                case GET_MANY: {
                    if (resource === RESOURCES.balance || resource === RESOURCES.btc_addresses)
                        return {
                            data: [{
                                id: params.ids[0],
                                ...response.data,
                            }],
                        };
                    else {
                        const responseData = response.data[resource].data;

                        return {
                            data: responseData,
                            total: response.data['total'],
                        }
                    }
                }
                default: {
                    const responseData = response.data[resource].data;

                    return {
                        data: responseData ? responseData : [],
                        total: response.data['total'],
                    }
                }
            }
        }
    }
};

export default responseHandler;
