import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { 
  Modal, 
  Card,
  Typography, 
  Grid, 
  InputLabel, 
  Select, 
  MenuItem, 
  Button, 
  FormControl,
  TextField
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import styles from '../styles';

const WalletTransactiosModal = memo((props) => (
  <Modal
    open={props.isOpenModal}
    style={styles.modalContainer}
    onClose={props.closeModal}
  >
    <Card style={styles.cardContainer}>
      <div style={styles.closeButtonContainer}>
        <Close style={styles.closeButton} onClick={props.closeModal} />
      </div>
      <Typography variant="title" style={styles.title}>
        Retiro de fondos
      </Typography>
      <form style={styles.formContainer}>
        <Grid container direction="column">
          <Grid container direction="row" alignItems="center">
            <Typography
              variant="body1"
              style={{ ...styles.body, marginTop: 20 }}
            >
              Criptomoneda a retirar
            </Typography>
            <FormControl style={styles.smallSelectContainer}>
              <InputLabel
                htmlFor="whitdrawal-funds-modal-crypto-select"
                style={styles.body}
              >
                Selecciona la moneda
              </InputLabel>
              <Select
                value={props.formData.currency}
                onChange={props.handleChange}
                style={styles.smallSelect}
                name="currency"
                id="whitdrawal-funds-modal-crypto-select"
                inputProps={{
                  name: "currency",
                  id: "whitdrawal-funds-modal-crypto-select",
                }}
              >
              {props.currenciesOptions &&
                props.currenciesOptions.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })
              }
              </Select>
            </FormControl>
          </Grid>
          <FormControl style={{ ...styles.selectContinaier, marginTop: 16 }}>
            <InputLabel
              htmlFor="whitdrawal-funds-modal-wallet-origin-select"
              style={styles.body}
            >
              Selecciona la wallet origen
            </InputLabel>
            <Select
              value={props.formData.walletOriginId}
              onChange={props.handleChange}
              style={styles.select}
              name="walletOriginId"
              id="whitdrawal-funds-modal-wallet-origin-select"
              inputProps={{
                name: "walletOriginId",
                id: "whitdrawal-funds-modal-wallet-origin-select",
              }}
            >
              {
                props.walletsOrigin && props.walletsOrigin.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {`${item.attributes.wallet_name} - ${item.attributes.network.key_name}`}
                    </MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
          <FormControl style={{ ...styles.selectContinaier, marginTop: 16 }}>
            <InputLabel
              htmlFor="whitdrawal-funds-modal-wallet-destination-select"
              style={styles.body}
            >
              Selecciona la wallet destino
            </InputLabel>
            <Select
              value={props.formData.walletDestinationId}
              onChange={props.handleChange}
              style={styles.select}
              name="walletDestinationId"
              disabled={!props.isWalletsDestintationActive}
              id="whitdrawal-funds-modal-wallet-destination-select"
              inputProps={{
                name: "walletDestinationId",
                id: "whitdrawal-funds-modal-wallet-destination-select",
              }}
            >
              {
                props.walletsDestintation && props.walletsDestintation.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {`${item.attributes.wallet_name} - ${item.attributes.network.key_name}`}
                    </MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
          >
            <Grid item>
              <TextField
                value={props.formData.amount}
                onChange={props.handleChange}
                name="amount"
                label="Monto a enviar"
                placeholder="Monto a enviar"
                style={styles.textFieldSmall}
                margin="normal"
              />
            </Grid>
            <Grid item>
              <TextField
                value={`${props.balance} ${props.currency}`}
                label="Saldo disponible"
                placeholder="Saldo disponible"
                style={{ ...styles.textFieldSmall, border: "none" }}
                margin="normal"
                disabled
              />
            </Grid>
          </Grid>
          <Grid container>
            <TextField
              value={props.formData.description}
              onChange={props.handleChange}
              name="description"
              label="Descripción"
              placeholder="Descripción"
              style={{ ...styles.textField }}
              margin="normal"
            />
          </Grid>
        </Grid>
      </form>
      <Grid container direction="row" spacing={32} justify="center">
        <Grid item>
          <Button style={styles.cancelButton} onClick={props.closeModal}>
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={props.isValid ? styles.submitButton : styles.disabledButton}
            disabled={!props.isValid}
            onClick={props.onSubmit}
          >
            Retirar
          </Button>
        </Grid>
      </Grid>
    </Card>
  </Modal>
));
WalletTransactiosModal.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  balance: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default WalletTransactiosModal;