import React, {useState} from 'react';
import {Card, TextField} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import useTransferRules from '../../../hooks/useTransferRules';
import useCrateFieldRule from '../../../hooks/useCreateFieldRule';
import useCountries from "../../../hooks/useCountries";

const Form = (props) => {
    return (
        <>
            {props.isSectionCreate && <div style={{paddingBottom: 10, paddingTop: 20}}>
                <TextField
                    label={'Nombre de la sección'}
                    value={props.sectionName}
                    onChange={props.changeSectionName}
                    style={{width: '100%'}}
                />
            </div>}
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <div style={{display: 'flex', width: 200, flexDirection: 'column', paddingTop: 10, paddingBottom: 10, color: '#FFFFFF'}}>
                    <div style={{display: 'flex', flexDirection: 'row', width: 200}}>
                        <div>
                            <div style={{fontSize: 10}}>Etiqueta</div>
                            <TextField value={props.field.label} onChange={props.changeLabel} />
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex', width: 200, flexDirection: 'column', paddingTop: 10, paddingBottom: 10, color: '#FFFFFF'}}>
                    <div style={{display: 'flex', flexDirection: 'row', width: 200}}>
                        <div>
                            <div style={{fontSize: 10}}>Campo</div>
                            <TextField value={props.field.name} onChange={props.changeName} />
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: 80, paddingTop: 10, paddingBottom: 10, color: '#FFFFFF'}}>
                    <div style={{fontSize: 10}}>Tipo</div>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.field.type}
                        onChange={props.changeType}
                    >
                        <MenuItem value="text">text</MenuItem>
                        <MenuItem value="email">email</MenuItem>
                        <MenuItem value="numeric">numeric</MenuItem>
                        <MenuItem value="select">select</MenuItem>
                    </Select>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: 80, paddingTop: 10, paddingBottom: 10, color: '#FFFFFF'}}>
                    <div style={{fontSize: 10}}>Min</div>
                    <TextField value={props.field.min} onChange={props.changeMin} />
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: 80, paddingTop: 10, paddingBottom: 10, color: '#FFFFFF'}}>
                    <div style={{fontSize: 10}}>Max</div>
                    <TextField value={props.field.max} onChange={props.changeMax} />
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: 80, paddingTop: 10, paddingBottom: 10, color: '#FFFFFF'}}>
                    <div style={{fontSize: 10}}>Col Grid</div>
                    <TextField value={props.field.col} onChange={props.changeCol} />
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: 80, paddingTop: 10, paddingBottom: 10, color: '#FFFFFF'}}>
                    <div style={{fontSize: 10}}>Posición</div>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.field.position}
                        onChange={props.changePosition}
                        disabled={props.isDisabledPositionSelect}
                    >
                        <MenuItem value="right">Derecha</MenuItem>
                        <MenuItem value="left">Izquierda</MenuItem>
                    </Select>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: 100, paddingTop: 10, paddingBottom: 10, color: '#FFFFFF'}}>
                    <div style={{fontSize: 10}}>Visibilidad</div>
                    <TextField label={'campo'} value={props.keyVisible} onChange={props.changeKeyVisible} />
                    <TextField label={'valor'} value={props.labelVisible} onChange={props.changeLabelVisible} />
                </div>
                <div style={{width: 400}}>
                    {props.options && props.options.map((option, index) => (
                        <div key={'create' + index} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                            <TextField
                                label={'etiqueta'}
                                value={option.label}
                                onChange={(event) => props.addOption(event, 'label', index)}
                            />
                            <TextField
                                label={'valor'}
                                value={option.value}
                                onChange={(event) => props.addOption(event, 'value', index)}
                            />
                            <div style={{width: 30, height: 30}}>
                                {index > 0 && <div onClick={() => props.deleteOption(index)} style={{fontSize: 12, cursor: 'pointer', justifyContent: 'center', backgroundColor: '#ff003b', width: 30, display: 'flex', alignItems: 'center', height: 30, borderRadius: 5}}><DeleteIcon style={{color: '#FFFFFF', fontSize: 15}} /></div>}
                            </div>
                        </div>
                    ))}
                    {props.options && <div onClick={props.addNewOption} style={{marginTop: 10, fontSize: 12, cursor: 'pointer', justifyContent: 'center', backgroundColor: '#0090ff', width: '100%', display: 'flex', alignItems: 'center', height: 30, borderRadius: 5}}>Agregar opción</div>}
                </div>
                <div style={{paddingLeft: 10}}>
                    <div onClick={props.createField} style={{fontSize: 12, cursor: 'pointer', justifyContent: 'center', backgroundColor: props.isValidForm ? '#0090ff' : '#292929', width: 30, display: 'flex', alignItems: 'center', height: 30, borderRadius: 5}}><SaveIcon style={{color: props.isValidForm ? '#FFFFFF' : '#5d5d5d', fontSize: 15}} /></div>
                </div>
            </div>
        </>
    );
};

const Add = (props) => {
    const {isSectionCreate = false} = props;
    const hook = useCrateFieldRule(props);
    const {changeAddField, isAddField} = hook;
    return (
        <>
            {isAddField && (
                <Form {...hook} isSectionCreate={isSectionCreate} />
            )}
            {!isSectionCreate && <div onClick={changeAddField} style={{fontSize: 15, marginTop: 20, textAlign: 'center', display: 'flex', cursor: 'pointer', alignItems: 'center', justifyContent: 'center', backgroundColor: isAddField ? '#ff003b' : '#00a7ff', width: 150, height: 35, borderRadius: 5}}>{isAddField ? 'remover campo' : 'Agregar campo'}</div>}
        </>
    );
};

const CreateSection = (props) => {
  const [isVisibleAddField, setVisibleAddField] = useState(false);

  return (
      <div style={{paddingBottom: 40}}>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <div onClick={() => setVisibleAddField(!isVisibleAddField)} style={{fontSize: 12, justifyContent: 'center', cursor: 'pointer', backgroundColor: isVisibleAddField ? '#ff003b' : '#00a7ff', width: 200, display: 'flex', alignItems: 'center', height: 35, borderRadius: 5}}>{!isVisibleAddField ? 'Agregar nueva sección' : 'Deshacer'}</div>
              {!props.isBankRule(props.rule) && props.rule.is_banks && <div onClick={() => props.createBankSection(props.countryIsoCode)} style={{fontSize: 12, justifyContent: 'center', cursor: 'pointer', backgroundColor: '#00a7ff', width: 200, display: 'flex', alignItems: 'center', height: 35, borderRadius: 5, marginLeft: 10}}>Agregar sección bancaria</div>}
              {!props.rule.is_banks && <div style={{fontSize: 12, justifyContent: 'center', backgroundColor: '#282828', width: 300, display: 'flex', alignItems: 'center', height: 35, borderRadius: 5, marginLeft: 10}}>Sin bancos registrados</div>}
          </div>
          {isVisibleAddField && <Add {...props} isSectionCreate onAdd={() => setVisibleAddField(false)} />}
      </div>
  );
};

const List = (props) => {
    const {
        rules,
        optionsSettings,
        showOptions,
        hiddenOptions,
        deleteField,
        deleteSection,
        countries,
        changeCountry,
        countrySelect,
        onRulesFetch,
        createBankSection,
        isBankRule,
        pricesCountriesWhitOutRules,
        countryCreateSelect,
        changeCreateCountry,
        changeCountryCreate,
        isCreateCountry,
        createRuleForNewCountry,
    } = props;

    return (
        <Card style={{padding: 30}}>
            <div style={{paddingBottom: 50, display: 'flex', flexDirection: 'row'}}>
                {countryCreateSelect === '' && <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={countrySelect}
                    onChange={changeCountry}
                    style={{width: 200, marginRight: 10}}
                >
                    {countries.map((country) => (<MenuItem key={`${country.name}-${country.id}`} value={country.id}>{country.name}</MenuItem>))}
                </Select>
                }
                <div onClick={changeCountryCreate} style={{fontSize: 12, marginRight: 10, justifyContent: 'center', cursor: 'pointer', backgroundColor: isCreateCountry ? '#ff003b' : '#00a7ff', width: 200, display: 'flex', alignItems: 'center', height: 35, borderRadius: 5, color: '#FFFFFF'}}>{isCreateCountry ? 'Deshacer' : 'Agregar nuevo país'}</div>
                {isCreateCountry && <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={countryCreateSelect}
                    onChange={changeCreateCountry}
                    style={{width: 200}}
                >
                    {pricesCountriesWhitOutRules.map((country) => (<MenuItem key={`transferRules${country.name}-${country.id}`} value={country.id}>{country.name}</MenuItem>))}
                </Select>}
            </div>
            {isCreateCountry && countryCreateSelect && (
                <div style={{paddingBottom: 50, display: 'flex', flexDirection: 'row'}}>
                    <div onClick={createRuleForNewCountry} style={{fontSize: 12, marginRight: 10, justifyContent: 'center', cursor: 'pointer', backgroundColor: '#00a7ff', width: 200, display: 'flex', alignItems: 'center', height: 35, borderRadius: 5, color: '#FFFFFF'}}>{'Agregar reglas a pais'}</div>
                </div>
            )}
            {countryCreateSelect === '' && Object.values(rules).map((rule, index) => {
                if (rule.iso_code.toLowerCase() !== countrySelect) {
                    return null;
                }

                return (
                    <div key={index}>
                            <div style={{display: 'flex', flexDirection: 'column', color: '#FFFFFF'}}>
                                <CreateSection
                                    rule={rule}
                                    countryIsoCode={rule.iso_code}
                                    onRulesFetch={onRulesFetch}
                                    createBankSection={createBankSection}
                                    isBankRule={isBankRule}
                                />
                                {rule.rules.map((section) => {
                                    return (
                                        <div key={section.label + index} style={{paddingBottom: 50, paddingTop: 10}}>
                                            <div style={{paddingBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                               {section.label}
                                                <div style={{paddingLeft: 20}}>
                                                    <div onClick={section.isFrequentAccountField ? () => deleteSection(section.id) : null} style={section.isFrequentAccountField ? {fontSize: 12, cursor: 'pointer', justifyContent: 'center', backgroundColor: '#ff003b', width: 30, display: 'flex', alignItems: 'center', height: 30, borderRadius: 5} : null}>{section.isFrequentAccountField && <DeleteIcon style={{color: '#FFFFFF', fontSize: 15}} />}</div>
                                                </div>
                                            </div>
                                            {section.fields.map((field) => {
                                                return (
                                                    <div key={field.label + index} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                                        <div style={{display: 'flex', width: 200, flexDirection: 'column', paddingTop: 10, paddingBottom: 10, color: '#FFFFFF'}}>
                                                            <div style={{display: 'flex', flexDirection: 'row', width: 200}}>
                                                                <div>
                                                                    <div style={{fontSize: 10}}>Etiqueta</div>
                                                                    {field.label}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{display: 'flex', width: 200, flexDirection: 'column', paddingTop: 10, paddingBottom: 10, color: '#FFFFFF'}}>
                                                            <div style={{display: 'flex', flexDirection: 'row', width: 200}}>
                                                                <div>
                                                                    <div style={{fontSize: 10}}>Campo</div>
                                                                    {field.name.replace("_select", "")}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{display: 'flex', flexDirection: 'column', width: 80, paddingTop: 10, paddingBottom: 10, color: '#FFFFFF'}}>
                                                            <div style={{fontSize: 10}}>Tipo</div>
                                                            {field.type}
                                                        </div>
                                                        <div style={{display: 'flex', flexDirection: 'column', width: 80, paddingTop: 10, paddingBottom: 10, color: '#FFFFFF'}}>
                                                            <div style={{fontSize: 10}}>Min</div>
                                                            {field.min ? field.min : ''}
                                                        </div>
                                                        <div style={{display: 'flex', flexDirection: 'column', width: 80, paddingTop: 10, paddingBottom: 10, color: '#FFFFFF'}}>
                                                            <div style={{fontSize: 10}}>Max</div>
                                                            {field.max ? field.max : ''}
                                                        </div>
                                                        <div style={{display: 'flex', flexDirection: 'column', width: 80, paddingTop: 10, paddingBottom: 10, color: '#FFFFFF'}}>
                                                            <div style={{fontSize: 10}}>Col Grid</div>
                                                            {field.col ? field.col : ''}
                                                        </div>
                                                        <div style={{display: 'flex', flexDirection: 'column', width: 80, paddingTop: 10, paddingBottom: 10, color: '#FFFFFF'}}>
                                                            <div style={{fontSize: 10}}>Posición</div>
                                                            {field.left ? 'Izquierda' : field.right ? 'Derecha' : ''}
                                                        </div>
                                                        <div style={{display: 'flex', flexDirection: 'column', width: 100, paddingTop: 10, paddingBottom: 10, color: '#FFFFFF'}}>
                                                            <div style={{fontSize: 10}}>Visibilidad</div>
                                                            {field.visible ? `${field.visible.key} = ${field.visible.value}` : 'Siempre'}
                                                        </div>
                                                        {field.options && optionsSettings[`${index}${field.name}`] && <div style={{display: 'flex', width: 400, flexDirection: 'column', paddingTop: 10, paddingBottom: 10, color: '#FFFFFF'}}>
                                                            <div style={{display: 'flex', flexDirection: 'row', width: 300}}>
                                                                <div>
                                                                    <div onClick={() => hiddenOptions(`${index}${field.name}`)} style={{padding: 7, backgroundColor: '#ff003b', borderTopLeftRadius: 3, borderBottomLeftRadius: 3, fontSize: 12, cursor: 'pointer'}}>Ocultar opciones</div>
                                                                    {field.options.map((option) => (
                                                                        <div key={option.value + index}>
                                                                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                                                                <div style={{display: 'flex', flexDirection: 'column', width: 300, paddingTop: 10, paddingBottom: 10, color: '#FFFFFF'}}>
                                                                                    <div style={{fontSize: 10}}>Etiqueta</div>
                                                                                    {option.label}
                                                                                </div>
                                                                                <div style={{display: 'flex', flexDirection: 'column', width: 100, paddingTop: 10, paddingBottom: 10, color: '#FFFFFF'}}>
                                                                                    <div style={{fontSize: 10}}>Valor</div>
                                                                                    {option.value}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>}
                                                        {field.options && !optionsSettings[`${index}${field.name}`] && (
                                                            <div style={{paddingTop: 10, paddingBottom: 10, width: 400}}>
                                                                <div onClick={() => showOptions(`${index}${field.name}`)} style={{padding: 7, backgroundColor: '#292929', borderTopLeftRadius: 3, borderBottomLeftRadius: 3, fontSize: 12, cursor: 'pointer'}}>Ver opciones</div>
                                                            </div>
                                                        )}
                                                        {!field.options && (
                                                            <div style={{paddingTop: 10, paddingBottom: 10, width: 400}}>
                                                                <div style={{padding: 7, borderRadius: 3, fontSize: 12, cursor: 'pointer'}}></div>
                                                            </div>
                                                        )}
                                                        <div style={{paddingLeft: 10}}>
                                                            <div onClick={section.isFrequentAccountField && field.name !== 'bank_id_select' ? () => deleteField(field.id) : null} style={section.isFrequentAccountField && field.name !== 'bank_id_select' ? {fontSize: 12, cursor: 'pointer', justifyContent: 'center', backgroundColor: '#ff003b', width: 30, display: 'flex', alignItems: 'center', height: 30, borderRadius: 5} : {width: 30, height: 30}}>{section.isFrequentAccountField && field.name !== 'bank_id_select' && <DeleteIcon style={{color: '#FFFFFF', fontSize: 15}} />}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            {section.isFrequentAccountField && <Add section={section.id} onRulesFetch={onRulesFetch} />}
                                        </div>
                                    )
                                })}
                            </div>
                    </div>
                );
            })}
        </Card>
    );
};

const TransferRulesList = () => {
    const hook = useTransferRules();
    const hookCountries = useCountries();
    return <List {...hook} {...hookCountries} />;
};

export default TransferRulesList;
