const styles = {
  modalContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    borderRadius: "10px",
    color: "#fff",
    padding: 32,
    width: 370,
    position: "relative",
  },
  closeButtonContainer: {
    position: "absolute",
    top: 16,
    right: 16,
    cursor: "pointer",
    width: 24,
    height: 24,
  },
  closeButton: {
    color: "#DFDFDF",
  },
  title: {
    fontSize: 24,
  },
  body: {
    fontSize: 12,
  },
  subtitle: {
    fontSize: 14,
    color: "#00BB8D",
    fontWeight: 600,
  },
  formContainer: {
    width: "100%",
    marginTop: 48,
    marginBottom: 48,
  },
  selectContinaier: {
    position: "relative",
  },
  select: {
    width: "100%",
  },
  smallSelectContainer: {
    position: "relative",
    marginLeft: 20,
  },
  smallSelect: {
    width: 160,
  },
  textField: {
    width: "100%",
    fontSize: 12,
  },
  textFieldSmall: {
    width: 160,
    fontSize: 12,
  },
  submitButton: {
    backgroundColor: "#005FEE",
  },
  disabledButton: {
    backgroundColor: "#DFDFDF",
  },
  cancelButton: {
    backgroundColor: "#E9320A",
  },
};

export default styles;