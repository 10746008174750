import { apiCall } from '../../Middlewares';
import { ENDPOINTS } from '../../Config/env';

const updateCryptoWallet = async (record_id, data) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.CRYPTO_WALLETS.UPDATE(record_id),
        data: { ...data }
    });
};

export default {
    updateCryptoWallet
}
