const responseHandler = (response, type) => {

  let result = [];

  if (response.data.wallets.data && response.data.wallets.data.length > 0) {
    result = response.data.wallets.data.map((wallet) => {      
      return {
        id: wallet.id,
        type: wallet.type,
        ...wallet.attributes,
        currency: wallet.attributes.currency.iso_code,
      };
    });
  }

  switch (type) {    
    default: {
      return {
        data: result,
        total: response.data['total'],
      }
    }
  };
}

export default responseHandler;
