import { GET_LIST } from 'react-admin';

import { API_URL } from '../../../../Config/env';

const requestHandler = (type, params) => {

    let { pagination: { page, perPage }, sort: { field, order } }= params;    

    switch (type) {
        case GET_LIST: {
            return {
                url: `${API_URL}/admin/custody_wallets/transactions?page=${page}&count=${perPage}&q[s]=${field}+${order}`,
                method: 'get',
            };
        }
        default: {
            return {

            }
        }
    }
}

export default requestHandler;
